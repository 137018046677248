<template>
	<div v-if="!this.isEmpty(this.fields)"
		 id="customFieldsList"
		 class="accordion accordion-flush mb-3">
		<div v-for="block in this.fields.blocks"
			 :key="block.id"
			 class="accordion-item">
			<h2 v-show="!expanded" class="accordion-header" :id="'customFieldsBlockHeading' + block.id">
				<button class="accordion-button collapsed"
						type="button"
						data-bs-toggle="collapse"
						:data-bs-target="'#customFieldsBlock' + block.id"
						:aria-expanded="expanded"
						aria-controls="'customFieldsBlockHeading' + block.id" v-text="block.name" />
			</h2>
			<div :id="'customFieldsBlock' + block.id"
				 class="accordion-collapse collapse" :class="{'show' : expanded}"
				 :aria-labelledby="'customFieldsBlockHeading' + block.id"
				 data-bs-parent="#customFieldsList">
				<div v-if="!this.isEmpty(this.fields.fields)" class="accordion-body">
					<div v-for="(field, index) in this.fields.fields.filter((field) => field.block_id == block.id)" :key="index">
						<div v-if="field.type == 'text'">
							<InputText
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="rounded-1 mb-10"
								inputWrapClass="flex-column mb-3"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'textarea'">
							<TextArea
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="rounded-1 mb-10"
								inputWrapClass="flex-column mb-3"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'checkbox'">
							<InputCheckbox
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="mb-20"
								inputWrapClass="flex-column mb-3"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'switcher'">
							<Switcher
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="px-0"
								inputWrapClass="flex-column mb-3"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'radio'">
							<InputRadio
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="mb-20"
								inputWrapClass="flex-column mb-3"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'number'">
							<InputNumber
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="rounded-1 mb-10"
								inputWrapClass="flex-column mb-3"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'select'">
							<InputSelect
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="rounded-1"
								inputUsePlaceholder="true"
								inputWrapClass="flex-column mb-3"
								:inputOptions="field.options"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
						<div v-else-if="field.type == 'multi-select'">
							<SelectBox
								:inputId="field.id"
								:inputLabel="field.label"
								:inputName="field.name"
								:ref="field.name"
								:inputRequired="field.is_required"
								:inputValue="this.getValue(field.name) || field.value"
								inputClass="rounded-1 p-2"
								inputWrapClass="flex-column mb-3"
								:inputOptions="field.options"
								inputSearch="false"
								inputMultiple="true"
								@setValue="(value) => this.prepareToSetValue(field.name, value)" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import CommonService from "@/services/CommonService";
import InputText from "@/components/inputs/InputText";
import InputNumber from "@/components/inputs/InputNumber";
import TextArea from "@/components/inputs/TextArea";
import InputRadio from "@/components/inputs/InputRadio";
import InputCheckbox from "@/components/inputs/InputCheckbox";
import InputSelect from "@/components/inputs/InputSelect";
import SelectBox from "@/components/inputs/SelectBox";
import Switcher from "@/components/inputs/Switcher";

export default {
	name: "CustomFieldsList",
	props: {
		customValues: {type: [Object, Array], default: () => []},
		customFields: {type: [Object, Array], default: () => []},
		expanded: {type: Boolean, default: false},
		is_filter: {type: Boolean, default: false}
	},
	data() {
		return {
			/*values: (typeof this.customValues !== "undefined") ? this.customValues : [],
			fields: (typeof this.customFields !== "undefined") ? this.customFields : [],*/
			errors: [],
		}
	},
	components: {
		InputText,
		InputNumber,
		TextArea,
		InputRadio,
		InputCheckbox,
		InputSelect,
		SelectBox,
		Switcher,
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		getValue(field_name) {

			let value = null;
			if (this.values && !this.isEmpty(this.values)) {
				if (typeof this.values[field_name] !== "undefined") {
					value = this.values[field_name];
				}
			}

			return value;
		},
		setValue(field_name, field_value) {

			if (process.env.NODE_ENV == "development")
				console.debug('setValue()', {
					[field_name]: field_value,
				});

			/*this.values = [...this.values, {
				[field_name]: field_value
			}];*/

			this.$emit('setValue', {
				'name': field_name,
				'value': field_value,
			});

			this.$emit('setValues', this.values);
		},
		prepareToSetValue(name, value) {
			CommonService.debounce(() => this.setAndValidate(name, value), 500);
		},
		setAndValidate(name, value) {

			let errors = [];
			
			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
				if (this.$refs[name].inputLabel) {

					let label = this.$refs[name].inputLabel.replace(/:+$/, '');
					if (this.$refs[name].required && !this.$props.is_filter) {
						if (value) {
							if (this.$refs[name].required && value.toString() == '0')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.$refs[name].required && this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if (!this.isEmpty(errors)) {
						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}
				}
			}

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });

			// Установка значения
			this.setValue(name, value);

			if (!this.isEmpty(errors))
				return errors;

			return true;
		},
		validateAll() {

			let is_valid = true;
			this.errors = [];
			for (const [name, value] of Object.entries(this.fields)) {
				let results = this.setAndValidate(name, value);
				if (results !== true) {
					is_valid = false;
					this.errors.push(results);
					this.$emit('inputInvalid', {
						'name': name,
						'value': value,
					});
				}
			}

			CommonService.log('debug', 'setAndValidate()', { is_valid: is_valid, errors: this.errors });

			return is_valid;
		},
	},
	mounted() {
		console.log(this.values);
		console.log(this.fields);
	},
	watch: {
		'values': function(val, oldVal) {

			CommonService.log('debug', 'CustomFieldsList::values', val);

		},
		'fields': function(val, oldVal) {

			CommonService.log('debug', 'CustomFieldsList::fields', val);

		},
	},
	computed: {
		/*values() {
			return this.$props.customValues ?? [];
		},
		fields() {
			return this.$props.customFields ?? [];
		},*/
		values: {
			get(_this) {
				return (typeof _this.$props.customValues !== "undefined") ? _this.$props.customValues : [];
			},
			set(value) {
				value = !this.isEmpty(value) ? value : [];
			}
		},
		fields: {
			get(_this) {
				return (typeof _this.$props.customFields !== "undefined") ? _this.$props.customFields : [];
			},
			set(value) {
				value = !this.isEmpty(value) ? value : [];
			}
		},
	},
}
</script>